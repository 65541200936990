<template>
  <div @dblclick="handleClickEdit" class="temp-div" :style="{ display: 'inline-block' }">
    <span v-if="!isEdit || !computedCanEdit">
      <!-- 问题归纳 -->
      <span v-if="props.type === ProjectTaskCommentElaborate.Summary">
        <span v-if="!props.data.content">
          <!-- 不存在概述内容 -->
          <span v-if="props.data.children && props.data.children.length === 1">
            <!-- 判断是筛选号还是详情 -->
            <span v-if="props.data.children[0].type === ProjectTaskCommentElaborate.FilterNumber">
              <!-- 如果是筛选号 -->
              <span v-if="props.data.children[0].content">
                <!-- 存在筛选号内容 -->
                <span v-if="props.data.children[0].children && props.data.children[0].children.length === 1">
                  <!-- 存在只有一个详情 -->
                  <span ref="spanDom">{{ props.before1 }}&nbsp; {{ props.data.content }}筛选号 {{ props.data.children[0].content }} ,
                    {{ props.data.children[0].children[0].content }}</span>
                </span>
              </span>
              <span v-else>
                <!-- 不存在筛选号内容 -->
                <span v-if="props.data.children[0].children && props.data.children[0].children.length === 1">
                  <!-- 存在只有一个详情 -->
                  <span ref="spanDom">{{ props.before1 }}&nbsp; {{ props.data.content }} 
                    {{ props.data.children[0].children[0].content }}</span>
                </span>
              </span>
            </span>
            <span v-else-if="props.data.children[0].type === ProjectTaskCommentElaborate.Detail">
              <!-- 如果是详情 -->
              <span ref="spanDom">{{ props.before1 }}&nbsp;  {{ props.data.children[0].content }} </span>
            </span>
          </span>
        </span>
        <span ref="spanDom" v-else>
          {{ props.before1 }}&nbsp; {{ props.data.content }}
        </span>
      </span>

      <!-- <span ref="spanDom" v-if="props.type === ProjectTaskCommentElaborate.Summary">
        {{ props.before1 }} {{ props.data.content }}
      </span> -->

      <!-- 筛选号 -->
      <span v-else-if="props.type === ProjectTaskCommentElaborate.FilterNumber">
        <span v-if="props.data.children && props.data.children.length === 1">
          <!-- 代表下属只有一个详情数据 此时需要合并-->
          <span v-if="props.data.content">
            <!-- 存在内容 -->
            <!-- <span ref="spanDom">
              {{ props.before1 }}&nbsp; 筛选号 {{ props.data.content }},
              {{ props.data.children[0].data.problem_details }}</span
            > -->
            <span ref="spanDom">
              {{ props.before1 }}&nbsp; 筛选号 {{ props.data.content }},
              <span  v-html="computeEnter2BR(props.data.children[0].data.problem_details)"></span>
              </span
            >
          </span>
          <span v-else>
            <!-- 不存在内容 -->
            <!-- <span ref="spanDom">
              {{ props.before1 }}&nbsp; {{ " " }}
              {{ props.data.children[0].data.problem_details }}</span
            > -->
            <span ref="spanDom">
              {{ props.before1 }}&nbsp; {{ " " }}
              <span v-html="computeEnter2BR(props.data.children[0].data.problem_details) "></span>
              </span
            >
          </span>
        </span>
        <span v-else>
          <!-- 下属有多个则不需要合并 -->
          <span v-if="props.data.content">
            <!-- 存在内容 -->
            <span ref="spanDom">
              {{ props.before1 }}&nbsp; 筛选号 {{ props.data.content }}</span
            >
          </span>
          <span v-else>
            <!-- 不存在内容 -->
            <span ref="spanDom"> {{ props.before1 }} </span>
          </span>
        </span>

        <!-- 
        <span ref="spanDom" v-if="!props.data.content"> {{ props.before1 }} 筛选号 {{ computedOneProblemDetail }}</span>
        <span ref="spanDom" v-else> {{ props.before1 }} {{ computedOneProblemDetail }}</span> -->
      </span>
      <!-- 问题详情 -->
      <span ref="spanDom" v-else-if="props.type === ProjectTaskCommentElaborate.Detail">
       
        {{ props.before1 }}&nbsp;  <span v-html="computeEnter2BR(props.data.content ) "></span>
      </span>

      <span ref="spanDom" v-else-if="props.type === ProjectTaskCommentElaborate.Overview">
        {{ props.data.content }}
      </span>

      <span
        v-if="computedJumpShow"
        :style="{ marginLeft: '4px' }"
        class="icon iconfont icon-right-arrow"
        @click="handleJumpDiscovery"
      ></span>
    </span>
    <div v-else class="input-div" :style="{ display: 'inline-block' }">
      <el-input
        v-model="inputData"
        :style="{ width: '60%'}"
        type="textarea"
      ></el-input>
      <el-space :style="{ marginLeft: '4px' }">
        <el-button
          type="primary"
          circle
          :icon="Check"
          size="small"
          @click.stop="handleOk"
        ></el-button>
        <el-button
          circle
          size="small"
          type="danger"
          :icon="Close"
          @click.stop="() => (isEdit = false)"
        ></el-button>
      </el-space>
    </div>
  </div>
  <span class="el-badge-span" v-if="computedCommentCount(props.data.data.guid) > 0">
    <el-badge
      :value="computedCommentCount(props.data.data.guid)"
      type="success"
      class="item"
      v-if="
        props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
        props.projectTaskProgress !== ProjectTaskProgress.Ready &&
        props.projectTaskProgress !== ProjectTaskProgress.AuditImplementation &&
        props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
        props.projectTaskProgress !== ProjectTaskProgress.Complete
      "
    >
      <el-badge
        :value="computedNotReadCommentCount(props.data.data.guid)"
        class="notread-comment-count"
        v-if="computedNotReadCommentCount(props.data.data.guid) > 0"
      >
        <!-- 5555 -->
        <el-button
          type="primary"
          circle
          size="small"
          :icon="ChatDotSquare"
          @click="
            handleCommentClick(
              ProjectTaskCommentModule.Elaborate,
              t('Components.Project.ProjectTask.Report1.DiscoveryDescription.Dilatation')
            )
          "
        ></el-button>
      </el-badge>
      <el-button
        v-else
        type="primary"
        circle
        size="small"
        :icon="ChatDotSquare"
        @click="
          handleCommentClick(
            ProjectTaskCommentModule.Elaborate,
            t('Components.Project.ProjectTask.Report1.DiscoveryDescription.Dilatation')
          )
        "
      ></el-button>
    </el-badge>
  </span>
  <span class="el-badge-span" v-else>
    <el-button
      v-if="
        props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
        props.projectTaskProgress !== ProjectTaskProgress.Ready &&
        props.projectTaskProgress !== ProjectTaskProgress.AuditImplementation &&
        props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
        props.projectTaskProgress !== ProjectTaskProgress.Complete
      "
      type="primary"
      circle
      size="small"
      :icon="ChatDotSquare"
      @click="
        handleCommentClick(
          ProjectTaskCommentModule.Elaborate,
          t('Components.Project.ProjectTask.Report1.DiscoveryDescription.Dilatation')
        )
      "
    ></el-button>
  </span>
</template>
<script setup>
import { ref, defineProps, defineEmits, onMounted, onBeforeUnmount, computed } from "vue";
import { Close, Check, ChatDotSquare } from "@element-plus/icons-vue";
import { request_projecttask_discoverydescription_problem } from "@/api/apis";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";
import { useI18n } from "vue-i18n";
import {
  ProjectTaskProgress,
  ProjectTaskCommentModule,
  ProjectTaskCommentElaborate,
} from "@/consts/enum";
import { useRouter } from "vue-router";

const router = useRouter();
const { t } = useI18n();
const emits = defineEmits(["getProjectTaskDiscoveryAll"]);
const props = defineProps({
  groupData: {
    type: Object,
    default: () => {},
  },
  data: {
    type: Object,
    default: () => {},
  },
  projectDiscoveryData: {
    type: Array,
    default: () => [],
  },
  type: {
    // 1：问题归纳；2：筛选号；3：问题详述
    type: Number,
    default: () => 0,
  },
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  id: {
    type: String,
    default: () => "",
  },
  projectTaskProgress: {
    type: Number,
    default: () => 0,
  },
  prefix: {
    type: String,
    default: () => "",
  },
  index: {
    type: Number,
    default: () => 0,
  },
  commentModuleCount: {
    type: Array,
    default: () => [],
  },
  before1: {
    type: String,
    default: () => "",
  },
  canReview: {
    type: Boolean,
    default: () => false,
  },
});

const isEdit = ref(false);
const inputData = ref(props.text);
const spanDom = ref();

// 是否可以编辑
const computedCanEdit = computed(() => {
  return (
    props.projectTaskProgress === ProjectTaskProgress.ReportWriting ||
    props.projectTaskProgress === ProjectTaskProgress.Rewrite
  );
});
// 计算模块评论数量
const computedCommentCount = computed(() => (relateGuid) => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.find(
      (item) =>
        item.module === ProjectTaskCommentModule.Elaborate &&
        item.elaborate_module === props.type &&
        item.grading === props.groupData.grading &&
        item.main_category === props.groupData.main_category &&
        item.sub_category === props.groupData.sub_category &&
        item.relate_guid === relateGuid
    );
    if (findCommentModuleCountData) {
      return findCommentModuleCountData.count;
    }
  }
  return 0;
});
const computedNotReadCommentCount = computed(() => (relateGuid) => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.find(
      (item) =>
        item.module === ProjectTaskCommentModule.Elaborate &&
        item.elaborate_module === props.type &&
        item.grading === props.groupData.grading &&
        item.main_category === props.groupData.main_category &&
        item.sub_category === props.groupData.sub_category &&
        item.relate_guid === relateGuid
    );
    if (findCommentModuleCountData) {
      if (props.canReview) return findCommentModuleCountData.not_read_count_for_approver;
      else return findCommentModuleCountData.not_read_count;
    }
  }
  return 0;
});
//  处理只有一个问题详情的，采用另一种方式展现
const computedOneProblemDetail = computed(() => {
  if (props.data.children && props.data.children.length === 1) {
    // 只有一个的情况就直接拼接
    return props.data.content + "," + props.data.children[0].data.problem_details;
  } else {
    return props.data.content;
  }
});

const computedJumpShow = computed(()=>{
  if(props.type === ProjectTaskCommentElaborate.Detail)
    return true
  if(props.type === ProjectTaskCommentElaborate.FilterNumber && props.data.children.length === 1)
    return true
  if(props.type === ProjectTaskCommentElaborate.Summary){
    if(!props.data.content){
      if(props.data.children && props.data.children.length === 1){
        if(props.data.children[0].type === ProjectTaskCommentElaborate.FilterNumber){
          if(props.data.children[0].content){
            if(props.data.children[0].children && props.data.children[0].children.length === 1){
              return true
            }
          }else{
            if(props.data.children[0].children && props.data.children[0].children.length === 1){
              return true
            }
          }
        }else if(props.data.children[0].type === ProjectTaskCommentElaborate.Detail){
          return true
        }
      }
    }
  }
  return false
})

onMounted(() => {
  pubsub.subscribe(PubsubMessage.ProjectTaskReportDiscoveryClearOtherEdit, (_, data) => {
    if (data != props.id) {
      isEdit.value = false;
    }
  });
});

// 换行处理
const computeEnter2BR = computed(()=>(str)=>{
  // 将\n替换成<br>
  return str.replaceAll('\n','<br>')
})
const handleClickEdit = () => {
  let dd = props.data.content;
  if (
    props.type === ProjectTaskCommentElaborate.FilterNumber &&
    props.data.children &&
    props.data.children.length === 1
  ) {
    // 如果是筛选号类型，而且下面的详情只有一个，这时候修改的是问题详情
    dd = props.data.children[0].data.problem_details;
  }
  if(props.type === ProjectTaskCommentElaborate.Summary ){
    if(!props.data.content){
      if(props.data.children && props.data.children.length === 1){
        if(props.data.children[0].type === ProjectTaskCommentElaborate.FilterNumber){
          if(props.data.children[0].content){
            if(props.data.children[0].children && props.data.children[0].children.length === 1){
              // 存放的是详情
              dd = props.data.children[0].children[0].content
            }
          }else{
            if(props.data.children[0].children && props.data.children[0].children.length === 1){
              // 存放的是详情
              dd = props.data.children[0].children[0].content
            }
          }
        }else if(props.data.children[0].type === ProjectTaskCommentElaborate.Detail){
          dd = props.data.children[0].content
        }
      }
    }
  }

  pubsub.publish(PubsubMessage.ProjectTaskReportDiscoveryClearOtherEdit, props.id);
  isEdit.value = true;
  inputData.value = dd;
};
// 处理提交
const handleOk = async () => {
  isEdit.value = false;
  let elaborate_module = props.type;
  let project_task_discovery_guid = props.data.data.guid;
  if (
    props.type === ProjectTaskCommentElaborate.FilterNumber &&
    props.data.children &&
    props.data.children.length === 1
  ) {
    // 如果是筛选号类型，而且下面的详情只有一个，这时候修改的是问题详情
    project_task_discovery_guid = props.data.children[0].data.guid;
    elaborate_module = ProjectTaskCommentElaborate.Detail;
  }
  if(props.type === ProjectTaskCommentElaborate.Summary ){
    if(!props.data.content){
      if(props.data.children && props.data.children.length === 1){
        if(props.data.children[0].type === ProjectTaskCommentElaborate.FilterNumber){
          if(props.data.children[0].content){
            if(props.data.children[0].children && props.data.children[0].children.length === 1){
              // 存放的是详情
              elaborate_module =  props.data.children[0].children[0].type;
              project_task_discovery_guid = props.data.children[0].children[0].data.guid
            }
          }else{
            if(props.data.children[0].children && props.data.children[0].children.length === 1){
              // 存放的是详情
              elaborate_module = props.data.children[0].children[0].type;
              project_task_discovery_guid = props.data.children[0].children[0].data.guid
            }
          }
        }else if(props.data.children[0].type === ProjectTaskCommentElaborate.Detail){
          // dd = props.data.children[0].content
          elaborate_module = props.data.children[0].type;
              project_task_discovery_guid = props.data.children[0].data.guid
        }
      }
    }
  }



  const trans = {
    elaborate_module: elaborate_module,
    main_category: props.groupData.main_category,
    sub_category: props.groupData.sub_category,
    grading: props.groupData.grading,
    project_task_discovery_guid: project_task_discovery_guid,
    text: inputData.value,
    problem_summary: props.data.data.problem_summary,
    filter_number: props.data.data.filter_number,
  };
  await request_projecttask_discoverydescription_problem(props.projectTaskGuid, trans);
  // 更新父级数据
  emits("getProjectTaskDiscoveryAll");
};
// 评论点击
// number 是 编号比如F-1，subRelateGuid是用于拼接relateguid，比如是分级的评论，则最终relateguid = F-1_Grading
const handleCommentClick = (module, subTitle) => {
  let contentDom = undefined;
  if (module === ProjectTaskCommentModule.Elaborate) {
    contentDom = spanDom.value;
  }
  let subSubTitle = "";
  if (props.type === ProjectTaskCommentElaborate.Summary) {
    // 问题归纳
    subSubTitle = t(
      "Components.Project.ProjectTask.Report1.DiscoveryDescription.ProblemSummary"
    );
  } else if (props.type === ProjectTaskCommentElaborate.FilterNumber) {
    // 筛选号
    subSubTitle = t(
      "Components.Project.ProjectTask.Report1.DiscoveryDescription.FilterNumber"
    );
  } else if (props.type === ProjectTaskCommentElaborate.Detail) {
    // 问题详情
    subSubTitle = t(
      "Components.Project.ProjectTask.Report1.DiscoveryDescription.ProblemDetail"
    );
  } else if (props.type === ProjectTaskCommentElaborate.Overview) {
    // 概述
    subSubTitle = t(
      "Components.Project.ProjectTask.Report1.DiscoveryDescription.ProblemOverview"
    );
  }

  let title =
    t("Components.Project.ProjectTask.Report1.DiscoveryDescription.Title") +
    "→" +
    subTitle;
  if (subSubTitle) {
    title = title + "→" + subSubTitle;
  }
  const data = {
    projectTaskProgress: props.projectTaskProgress,
    title: title,
    module,
    mainCategory: props.groupData.main_category,
    subCategory: props.groupData.sub_category,
    grading: props.groupData.grading,
    elaborateModule: props.type,
    relateGuid: props.data && props.data.data ? props.data.data.guid : "",
    contentDom,
  };
  pubsub.publish(PubsubMessage.ProjectTaskReportComment, data);
};
// 处理跳转发现指定
const handleJumpDiscovery = () => {
  let project_task_discovery_guid = props.data.data.guid;
  if (
    props.type === ProjectTaskCommentElaborate.FilterNumber &&
    props.data.children &&
    props.data.children.length === 1
  ) {
    // 如果是筛选号类型，而且下面的详情只有一个，这时候修改的是问题详情
    project_task_discovery_guid = props.data.children[0].data.guid;
  }
  if(props.type === ProjectTaskCommentElaborate.Summary ){
    if(!props.data.content){
      if(props.data.children && props.data.children.length === 1){
        if(props.data.children[0].type === ProjectTaskCommentElaborate.FilterNumber){
          if(props.data.children[0].content){
            if(props.data.children[0].children && props.data.children[0].children.length === 1){
              // 存放的是详情
              project_task_discovery_guid = props.data.children[0].children[0].data.guid
            }
          }else{
            if(props.data.children[0].children && props.data.children[0].children.length === 1){
              // 存放的是详情
              project_task_discovery_guid = props.data.children[0].children[0].data.guid
            }
          }
        }else if(props.data.children[0].type === ProjectTaskCommentElaborate.Detail){
          // dd = props.data.children[0].content
              project_task_discovery_guid = props.data.children[0].data.guid
        }
      }
    }
  }

  const { href } = router.resolve({
    path: "/project/discovery",
    query: {
      guid: props.projectTaskGuid,
      discovery_guid: project_task_discovery_guid,
    },
  });
  window.open(href, "_blank");
};
onBeforeUnmount(() => {
  pubsub.unsubscribe(PubsubMessage.ProjectTaskReportDiscoveryClearOtherEdit);
  // pubsub.unsubscribe(PubsubMessage.ProjectTaskReportComment);
});
</script>
<style scoped lang="scss">
.notread-comment-count {
  ::v-deep .el-badge__content {
    margin-top: 20px;
    font-size: 0.8em;
  }
}
.input-div {
  display: flex;
  width: 100%;
  .el-space {
    padding-left: 12px;
    box-sizing: border-box;
  }
}
.temp-div {
  padding-left: 12px;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding-right: 28px;
  .icon-right-arrow {
    margin-left: 4px;
    &:hover {
      cursor: pointer;
      color: #3c96db;
    }
  }
}
.el-badge {
  // position: absolute;
  // right: 0;
}
.el-badge-span {
  position: absolute;
  right: 0;
}
</style>
