
<template>
  <!-- 发现报告 -->
  <el-page-header @back="() =>{
      if(discoveryGuid){
        router.push({path: '/project/projecttask'})
      }else{
        router.back()
      }
    }">
    <template #title>
      {{ t("Common.Back") }}
    </template>
  </el-page-header>
  <ul class="discovery-ul" v-loading="fullLoading">
    <li class="hm-search">
      <!-- <el-button
    @click="handleBack"
    :icon="ArrowLeft"
    type="primary"
    circle
    :style="{ marginRight: '8px' }"
  ></el-button> -->
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item
          :label="$t('Components.Project.ProjectTask.Discovery.Grading')"
        >
          <el-select
            v-model="searchForm.grading"
            filterable
            clearable
            :placeholder="
              t('Components.Project.ProjectTask.Discovery.PleaseSelectGrading')
            "
          >
            <el-option
              v-for="item in gradings"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.Project.ProjectTask.Discovery.MainCategory')"
        >
          <el-select
            v-model="searchForm.main_category"
            filterable
            clearable
            :placeholder="
              t(
                'Components.Project.ProjectTask.Discovery.PleaseSelectMainCategory'
              )
            "
            @change="handleMainCategoryChange"
          >
            <el-option
              v-for="item in mainCategorys"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.Project.ProjectTask.Discovery.SubCategory')"
        >
          <el-select
            v-model="searchForm.sub_category"
            filterable
            clearable
            :placeholder="
              t(
                'Components.Project.ProjectTask.Discovery.PleaseSelectSubCategory'
              )
            "
          >
            <el-option
              v-for="item in currentSubCategorys"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          :label="$t('Components.Project.ProjectTask.Discovery.AuditUser')"
        >
          <el-select
            v-model="searchForm.audit_user_guid"
            filterable
            clearable
            :placeholder="
              t(
                'Components.Project.ProjectTask.Discovery.PleaseSelectAuditUser'
              )
            "
          >
            <el-option
              v-for="item in users"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{
              $t("Components.Project.ProjectTask.Discovery.Search1")
            }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{
              $t("Components.Project.ProjectTask.Discovery.Reset")
            }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :disabled="projectTaskData.progress === ProjectTaskProgress.Complete"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.Project.ProjectTask.Discovery.Add") }}</el-button
      >
      <el-button
        v-if="buttonVisible.editButton"
        :disabled="
          !(selectDiscoverys && selectDiscoverys.length === 1) ||
          projectTaskData.progress === ProjectTaskProgress.Complete
        "
        :style="{ color: 'white' }"
        :icon="Edit"
        :color="ButtonColor.Edit"
        @click="handleEdit(1)"
        >{{ $t("Components.Project.ProjectTask.Discovery.Edit") }}</el-button
      >
      <el-button
        v-if="buttonVisible.deleteButton"
        :disabled="
          !(selectDiscoverys && selectDiscoverys.length > 0) ||
          projectTaskData.progress === ProjectTaskProgress.Complete
        "
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Delete"
        @click="handleDeleteBatch"
        >{{ $t("Components.Project.ProjectTask.Discovery.Delete") }}</el-button
      >
      <el-button
        v-if="buttonVisible.exportButton"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Export"
        @click="handleExport"
        >{{ $t("Components.Project.ProjectTask.Discovery.Export") }}</el-button
      >
      <el-upload
        v-if="buttonVisible.importButton"
        ref="uploadRef"
        accept=".xls, .xlsx"
        :action="request_discovery_import"
        :limit="1"
        name="file"
        :headers="{ token: token }"
        :on-success="handleUploadSuccess"
        :show-file-list="false"
        :data="{ guid: projectTaskGuid }"
      >
        <el-button
          v-if="buttonVisible.importButton"
          :disabled="projectTaskData.progress === ProjectTaskProgress.Complete"
          :style="{ color: 'white' }"
          :icon="Upload"
          :color="ButtonColor.Upload"
          >{{
            $t("Components.Project.ProjectTask.Discovery.Import")
          }}</el-button
        >
      </el-upload>
      <el-button
        v-if="buttonVisible.generateReportButton"
        :disabled="projectTaskData.progress === ProjectTaskProgress.Complete"
        :icon="Document"
        @click="handleGenerateReport"
        >{{
          $t("Components.Project.ProjectTask.Discovery.GenerateReport")
        }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="main_category"
            :label="$t('Components.Project.ProjectTask.Discovery.MainCategory')"
            width="120"
          >
            <template #default="scope">
              <div v-if="scope.row.main_category">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.DiscoveryMainCategory,
                      scope.row.main_category
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.DiscoveryMainCategory,
                      scope.row.main_category
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(
                      Dics.System.DiscoveryMainCategory,
                      scope.row.main_category
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.DiscoveryMainCategory,
                      scope.row.main_category
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.DiscoveryMainCategory,
                      scope.row.main_category
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="sub_category"
            :label="$t('Components.Project.ProjectTask.Discovery.SubCategory')"
            width="240"
          >
            <template #default="scope">
              <div v-if="scope.row.sub_category">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.DiscoverySubCategory,
                      scope.row.sub_category
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.DiscoverySubCategory,
                      scope.row.sub_category
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(
                      Dics.System.DiscoverySubCategory,
                      scope.row.sub_category
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.DiscoverySubCategory,
                      scope.row.sub_category
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.DiscoverySubCategory,
                      scope.row.sub_category
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="grading"
            :label="$t('Components.Project.ProjectTask.Discovery.Grading')"
            width="120"
          >
            <template #default="scope">
              <div v-if="scope.row.grading">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.DiscoveryGrading,
                      scope.row.grading
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.DiscoveryGrading,
                      scope.row.grading
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(
                      Dics.System.DiscoveryGrading,
                      scope.row.grading
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.DiscoveryGrading,
                      scope.row.grading
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.DiscoveryGrading,
                      scope.row.grading
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="problem_overview"
            :label="
              $t('Components.Project.ProjectTask.Discovery.ProblemOverview')
            "
            width="240"
          />
          <el-table-column
            prop="problem_summary"
            :label="
              $t('Components.Project.ProjectTask.Discovery.ProblemSummary')
            "
            width="240"
          />
          <el-table-column
            prop="filter_number"
            :label="$t('Components.Project.ProjectTask.Discovery.FilterNumber')"
            width="120"
          />
          <el-table-column
            width="240"
            :label="
              $t('Components.Project.ProjectTask.Discovery.ProblemDetail')
            "
          >
          <template #default="scope">
            <span v-html="computeEnter2BR(scope.row.problem_details)"></span>
            </template>
          </el-table-column>

          <el-table-column
            prop="remark"
            :label="$t('Components.Project.ProjectTask.Discovery.Remark')"
            width="120"
          />
          <el-table-column
            prop="audit_user_name"
            :label="$t('Components.Project.ProjectTask.Discovery.AuditUser')"
            width="120"
          />
          <el-table-column
            prop="is_import_report"
            :label="
              $t('Components.Project.ProjectTask.Discovery.IsImportReport')
            "
            width="120"
            fixed="right"
          >
            <template #default="scope">
              <el-switch
                :disabled="projectTaskData.progress === ProjectTaskProgress.Complete"
                v-model="scope.row.is_import_report"
                @change="(flag) => handleDataflagChange(flag, scope.row)"
              />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('Components.Project.ProjectTask.Discovery.Operate')"
            width="200"
            fixed="right"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :disabled="projectTaskData.progress === ProjectTaskProgress.Complete"
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEdit(2, scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.Discovery.Edit")
                  }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :disabled="projectTaskData.progress === ProjectTaskProgress.Complete"
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click="handleDelete(scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.Discovery.Delete")
                  }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>
    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
    <DiscoveryModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    @getProjectTaskDiscoverysPage="getProjectTaskDiscoverysPage"
    :projectTaskGuid="projectTaskGuid"
    :mainCategorys="mainCategorys"
    :subCategorys="subCategorys"
    :gradings="gradings"
    :modal="false"
:appendToBody="true"
  />
</template>
<script setup>
import { useRoute } from "vue-router";
import { reactive, ref, markRaw, onMounted, watchEffect, computed } from "vue";
import {
  Plus,
  Delete,
  RefreshLeft,
  Edit,
  Search,
  WarnTriangleFilled,
  Download,
  Upload,
  Document,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import {
  AllPageSize,
  ButtonColor,
  LocalStorageKey,
  DefaultPageSize,
} from "@/consts/frame";
import {
  request_projecttask_discovery_page,
  request_projecttask_discovery_delete,
  request_projecttask_discovery_delete_batch,
  request_projecttask_discovery_isimportreport,
  request_projecttask_discovery_export,
  request_discovery_import,
  request_user_page,
  request_projecttask_detail,
} from "@/api/apis";
// import AuditedPartyModify from "@/components/Database/AuditedParty/AuditedPartyModify";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";
import { router } from "@/router";
import DiscoveryModify from "@/components/Project/ProjectTask/DiscoveryModify.vue";
import { DictionaryItemEchoStyle, ProjectTaskProgress } from "@/consts/enum";
import statusCode from "@/api/statusCode";

const store = useStore();
const route = useRoute();
const { t } = useI18n();
const projectTaskGuid = ref("");
const discoveryGuid = (route.query && route.query.discovery_guid) ?? "";
const searchForm = reactive({
  grading: undefined,
  main_category: undefined,
  sub_category: undefined,
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  exportButton: false,
  generateReportButton: false,
  importButton: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref({});
// 弹窗是否可见
const dialogVisible = ref(false);
// 选择的被稽查方
const selectDiscoverys = ref([]);
// 全屏加载
const fullLoading = ref(false);
const gradings = ref([]); // 分级字典集合
const mainCategorys = ref([]); // 主分类字典集合
const subCategorys = ref([]); // 子分类典集合
const currentSubCategorys = ref([]); // 当前选择的主分类下面的子分类
const token = ref("");
const uploadRef = ref();
const users = ref([]);
const projectTaskData = ref({});

// 是否可以编辑
const computedCanEdit = computed(() => {
  // 只有 待分配 准备中 稽查实施 报告撰写可以 分配人员
  return '1231231231'
});
onMounted(() => {
  projectTaskGuid.value = (route.query && route.query.guid) ?? "";
  getProjectTaskDiscoverysPage();
  getProjectTaskDetail();
  getUserAll();
  if (localStorage) {
    token.value = localStorage.getItem(LocalStorageKey.Token);
  }
});
// 获取项目任务guid
const getProjectTaskDetail = async () => {
  const result = await request_projecttask_detail(projectTaskGuid.value);
  projectTaskData.value = result;
};
// 处理字典数据
const computeDicItem = computed(() => (dic, data) => {
  switch (dic) {
    case Dics.System.DiscoveryGrading: // 发现分级
      if (gradings.value) {
        const findData = gradings.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        } 
      }
      break;
    case Dics.System.DiscoveryMainCategory: // 发现主分类
      if (mainCategorys.value) {
        const findData = mainCategorys.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        } 
      }
      break;
    case Dics.System.DiscoverySubCategory: // 发现子分类
      if (subCategorys.value) {
        const findData = subCategorys.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        } 
      }
      break;
  }
});
// 换行处理
const computeEnter2BR = computed(()=>(str)=>{
  // 将\n替换成<br>
  return str.replaceAll('\n','<br>')
})
// 处理回显样式
const computeDicItemEchoStyle = computed(() => (dic, data) => {
  let echoStyle = "";
  switch (dic) {
    case Dics.System.DiscoveryGrading: // 分级
      if (gradings.value) {
        const findData = gradings.value.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.DiscoveryMainCategory: // 主分类
      if (mainCategorys.value) {
        const findData = mainCategorys.value.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.DiscoverySubCategory: // 子分类
      if (subCategorys.value) {
        const findData = subCategorys.value.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        } 
      }
      break;
  }
  return !echoStyle || echoStyle === DictionaryItemEchoStyle.Primary
    ? ""
    : echoStyle;
});

// 分页查询
const getProjectTaskDiscoverysPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    grading: searchForm.grading,
    main_category: searchForm.main_category,
    sub_category: searchForm.sub_category,
    audit_user_guid: searchForm.audit_user_guid,
    guid: discoveryGuid,
  };
  tableLoading.value = true;
  const result = await request_projecttask_discovery_page(
    projectTaskGuid.value,
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  selectDiscoverys.value = [];
  editData.value = {};
};

// 查询事件
const queryClick = () => {
  getProjectTaskDiscoverysPage();
};
// 重置事件
const resetClick = () => {
  searchForm.grading = undefined;
  searchForm.main_category = undefined;
  searchForm.sub_category = undefined;
  searchForm.audit_user_guid = "";
  getProjectTaskDiscoverysPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getProjectTaskDiscoverysPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getProjectTaskDiscoverysPage();
};
// 处理编辑事件
const handleEdit = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectDiscoverys.value.length !== 1) {
      ElMessage.warning(
        t("Components.Project.ProjectTask.Discovery.OnlyOneSelectEdit")
      );
      return;
    }
    row = selectDiscoverys.value[0];
  } else if (type === 2) {
    row = data;
  }
  editData.value = {
    guid: row.guid,
    main_category: row.main_category,
    sub_category: row.sub_category,
    grading: row.grading,
    filter_number: row.filter_number,
    problem_details: row.problem_details,
    problem_summary: row.problem_summary,
    problem_overview: row.problem_overview,
    remark: row.remark,
    is_import_report: row.is_import_report,
  };
  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
};
// 处理选择回调事件
const handleSelectionChange = (discoverys) => {
  selectDiscoverys.value = discoverys;
};
// 处理删除
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.Project.ProjectTask.Discovery.ConfirmDeletepDiscovery"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        project_task_discovery_guid: row.guid,
      };
      await request_projecttask_discovery_delete(
        projectTaskGuid.value,
        trans,
        true
      );
      getProjectTaskDiscoverysPage();
    })
    .catch(() => {});
};
// 批量删除
const handleDeleteBatch = async () => {
  if (selectDiscoverys.value.length === 0) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.Discovery.ConfirmDeletepDiscovery")
    );
    return;
  }
  ElMessageBox.confirm(
    t("Components.Project.ProjectTask.Discovery.AtLeastOneDiscovery"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        project_task_discovery_guids: selectDiscoverys.value.map(
          (item) => item.guid
        ),
      };
      await request_projecttask_discovery_delete_batch(
        projectTaskGuid.value,
        trans,
        true
      );
      getProjectTaskDiscoverysPage();
    })
    .catch(() => {});
};
// 处理主分类改变事件
const handleMainCategoryChange = (value) => {
  searchForm.sub_category = undefined;
  const findMainCategory = mainCategorys.value.find(
    (item) => item.value === value
  );
  if (findMainCategory) {
    const findSubCategorys = subCategorys.value.filter(
      (item) => item.remark === value
    );
    if (findSubCategorys && findSubCategorys.length > 0) {
      currentSubCategorys.value = findSubCategorys;
    } else {
      currentSubCategorys.value = [];
    }
  } else {
    currentSubCategorys.value = [];
  }
};
// 处理有效性改变事件
const handleDataflagChange = async (flag, row) => {
  const trans = {
    project_task_discovery_guid: row.guid,
  };
  await request_projecttask_discovery_isimportreport(
    projectTaskGuid.value,
    trans,
    true,
    () => {
      row.is_import_report = !row.is_import_report;
    }
  );
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    grading: searchForm.grading,
    main_category: searchForm.main_category,
    sub_category: searchForm.sub_category,
    audit_user_guid: searchForm.audit_user_guid,
  };
  const result = await request_projecttask_discovery_export(
    projectTaskGuid.value,
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 处理上传成功
const handleUploadSuccess = async (response) => {
  uploadRef.value.clearFiles();
  if (response.code === statusCode.Success) {
    ElMessage.success(response.message);
    getProjectTaskDiscoverysPage();
  } else {
    ElMessage.warning(response.message);
  }
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};
// 处理生成报告
const handleGenerateReport = async () => {
  // // 先初始化校验下
  // if (projectTaskData.value.progress === ProjectTaskProgress.ReportWriting || projectTaskData.value.progress === ProjectTaskProgress.Rewrite) {
  //   await request_projecttask_initreport(projectTaskGuid.value);
  // }
  router.push({
    path: "/project/generatereport",
    query: { guid: projectTaskGuid.value},
  });
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDicoverySearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDiscoveryReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDiscoveryAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDiscoveryEdit
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDiscoveryDelete
    );
    // 处理删除行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDiccoveryEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDiccoveryDeleteLine
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDiscoveryExport
    );
    // 处理生成报告
    buttonVisible.generateReportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDiscoveryGenerateReport
    );
    // 处理导入
    buttonVisible.importButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDiscoveryImport
    );
  }
});
// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      // 分级
      const findDiscoveryGradingDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.DiscoveryGrading
      );
      if (findDiscoveryGradingDic) {
        if (
          findDiscoveryGradingDic.items &&
          findDiscoveryGradingDic.items.length > 0
        ) {
          gradings.value = findDiscoveryGradingDic.items;
        } else {
          gradings.value = [];
        }
      } else {
        gradings.value = [];
      }
      // 主分类
      const findDiscoveryMainCategoryDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.DiscoveryMainCategory
      );
      if (findDiscoveryMainCategoryDic) {
        if (
          findDiscoveryMainCategoryDic.items &&
          findDiscoveryMainCategoryDic.items.length > 0
        ) {
          mainCategorys.value = findDiscoveryMainCategoryDic.items;
        } else {
          mainCategorys.value = [];
        }
      } else {
        mainCategorys.value = [];
      }

      // 子分类
      const findDiscoverySubCategoryDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.DiscoverySubCategory
      );
      if (findDiscoverySubCategoryDic) {
        if (
          findDiscoverySubCategoryDic.items &&
          findDiscoverySubCategoryDic.items.length > 0
        ) {
          subCategorys.value = findDiscoverySubCategoryDic.items;
        } else {
          subCategorys.value = [];
        }
      } else {
        subCategorys.value = [];
      }
    } else {
      gradings.value = [];
      mainCategorys.value = [];
      subCategorys.value = [];
    }
  }
});
</script>

<style>
.discovery-ul .el-scrollbar__view {
  height: calc(100% - 20px);
}
</style>
<style scoped lang="scss">
.discovery-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: calc(100% - 28px);;
}
</style>
